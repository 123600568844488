<template>
  <div class="page-404 py-4 text-center">
    <div class="logo">
      <router-link to="/" crm_at="router_to"> </router-link>
    </div>
    <div class="image-404">
      <img crm_at="image_404" src="@/assets/images/notfound/404.svg" alt="" />
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'

export default {
  name: 'PageNotFound',
  mixins: [Mixin],
  data() {
    return {}
  },
  created() {
    this.handleLoading(false)
    this.handleFooter(false)
  },
  methods: {
    handleLoading(value) {
      this.$store.commit('Loading', value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/main.scss';
.page-404 {
  .logo {
    margin: 20px 0 40px;
    img {
      width: 100px;
    }
  }
  .image-404 {
    img {
      max-width: 800px;
    }
  }
}
</style>
